.modal-header {
    display: flex;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;
    background: $brand;
    padding: 20px 34px;

    h3 {
        margin: 0;
        line-height: 28px;
        color: white;
        font-size: 22px;
        font-weight: normal;
    }

    .btn {
        background: darken($brand, 5%);
        color: white;

        &:hover {
            background: darken($brand, 7%);
            color: white;
        }
    }

    .btn-small {
        padding: 0 14px;
    }

    @include respond-to(mobile) {
        border-radius: 0;
        padding: 20px 17px;
    }
}

.header-success {
    background: var(--success);

    .btn {
        background: darken($success, 5%);

        &:hover {
            background: darken($success, 7%);
        }
    }
}

.header-danger {
    background: var(--danger);

    .btn {
        background: darken($danger, 5%);

        &:hover {
            background: darken($danger, 7%);
        }
    }
}

.header-warning {
    background: var(--warning);

    .btn {
        background: darken($warning, 5%);

        &:hover {
            background: darken($warning, 7%);
        }
    }
}
