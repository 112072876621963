@import '../../shared/settings';

.row-label {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    margin: 14px 0 6px;
    line-height: 24px;
    color: var(--dark-grey);
    font-size: 16px;
    font-weight: bold;

    a {
        margin-left: auto;
        text-decoration: underline;
        font-weight: normal;
        font-size: 12px;

        &:hover {
            text-decoration: underline;
        }
    }

    svg {
        margin-left: 8px;
        width: 12px;
        height: auto;

        &.checkmark {
            fill: var(--success);
        }

        &[fill="none"] {
            stroke: var(--danger);
            position: relative;
            top: 1px;
        }
    }

    .error {
        margin: 0 10px;
        color: var(--warning);
        font-size: 12px;
    }

    strong {
        color: $brand;
        font-weight: normal;
    }

    small {
        font-size: 14px;
        font-weight: normal;
        display: inline-block;
        margin-left: 6px;
    }
}

.tooltip-help {
    display: inline-block;
    position: relative;
    margin-left: 6px;
    width: 14px;
    height: 14px;

    & + .checkmark {
        margin: 0 0 0 4px;
    }

    &:hover {
        svg {
            fill: $grey;
        }
    }

    svg {
        display: block;
        fill: $light-grey;
    }
}
