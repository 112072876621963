@import '../../shared/settings';

.modal-content {
    position: relative;
    outline: none;
    border-radius: 10px;
    padding: 0;
    pointer-events: auto;

    .btn-close {
        position: absolute;
        top: 22px;
        right: var(--gutter-size);
    }

    p {
        line-height: 22px;
        font-size: 14px;
    }

    .bulleted,
    .decimal {
        line-height: 22px;
        font-size: 14px;

        li {
            margin-left: 16px;
        }
    }

    .bulleted {
        list-style: disc;
    }

    .decimal {
        list-style: decimal;
    }

    .modal-box {
        border-radius: 10px;
        background: white;
    }

    .modal-box {
        .inner {
            padding: 34px;

            &.success {
                background-color: var(--success);
            }

            &.warning {
                background-color: var(--danger);
            }

            &.buttons {
                border: 0;
                border-radius: 0 0 10px 10px;
                background-color: #f9f9fb;
                padding: 0 24px 24px;
            }
        }
    }

    .form-footer-button-center {
        justify-content: center;

        .btn {
            padding-right: 48px;
            padding-left: 48px;
        }
    }

    .grid-page-centered .grid-column {
        width: 100%;
    }

    @include respond-to(mobile) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 999;
        border-radius: 0;

        .modal-box {
            .inner {
                padding: 24px;
            }
        }
    }
}
