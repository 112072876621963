.theme-area-beezy {
    --brand: black;
    --brand-offset: #2B2B2B;
    --brand-offset-rgb: 43, 43, 43;
    --site-top-background: black;
}

.theme-area-bloemisten {
    --site-top-background: #3C3200;
}


.theme-area-groningen {
    --site-top-background: #1c2c3b;

    .site-header .logo svg {
        height: 36px;
    }
}

.theme-area-assen {
    --site-top-background: #1a171b;
}

.theme-area-denbosch {
    --site-top-background: #341d31;
}

.theme-area-geleen {
    --site-top-background: #0095da;
}

.theme-area-hilversum {
    --site-top-background: #1c2e50;
}

.theme-area-steenwijkerland {
    --site-top-background: #1b427a;
}

.theme-area-terneuzen {
    --site-top-background: #002c5c;
}

.theme-area-zeeland {
    --site-top-background: #00529c;
}

.theme-area-zwolle {
    --site-top-background: #131f28;
}
